<template>
  <div class="home">
    <div class="container is-fluid">
      <page-header :entity="$EntitiesName.User" :create="openCreateModal">
        <h1 class="title">Utilisateurs</h1>
      </page-header>

      <datatable :fetch="$Api.User.fetchUsers" :entity="$EntitiesName.User" ref="userList" :enableDownload="true">
        <div slot="name" slot-scope="{ item }" title="Nom" :sortable="true">{{ item.name }}</div>
        <div slot="mail" slot-scope="{ item }" title="E-Mail" :sortable="true">{{ item.mail }}</div>
        <div slot="role" slot-scope="{ item }" title="Profil" :sortable="true">
          <span v-if="item.role" :sortable="true">{{ item.role.name }}</span>
        </div>
        <div slot="manager" slot-scope="{ item }" title="Responsable" :sortable="true">
          <restricted-link v-if="item.manager" :entity="$EntitiesName.User" :to="`/user/${item.manager.id}`">
            {{ item.manager.name }} ({{ item.manager.quality }})
          </restricted-link>
        </div>
        <div slot="service" slot-scope="{ item }" title="Service" :sortable="true">{{ item.service }}</div>
        <div slot="quality" slot-scope="{ item }" title="Qualité" :sortable="true">{{ item.quality }}</div>
        <div slot="active" slot-scope="{ item }" title="Actif ?" :nofilter="true" :sortable="true">
          <checkbox-field v-model="item.active" :edit="false" :inline="true"/>
        </div>
      </datatable>
    </div>

    <modal ref="createUserModal" @create="createUser" title="Ajout d'un utilisateur">
      <div class="columns">
        <div class="column is-half">
          <text-field
            label="LDAP CommonName"
            v-model="user.name"
            :inline="false"
            :edit="true"
            data-test="sel-ldap-cn"
            required
          />

          <text-field label="Téléphone" data-test="sel-phone" v-model="user.phone" :inline="false" :edit="true"/>

          <selector-field
            label="Profil"
            v-model="user.role"
            :options="$Api.Role.fetchRolesAsList"
            :inline="false"
            :edit="true"
            :preselect="true"
            data-test="sel-user-role"
            required
          >
            <template slot-scope="{ option }">{{ option.name }}</template>
          </selector-field>

          <article
            v-if="user.role"
            slot="helper"
            class="message"
            :class="{
              'is-danger': user.role.visibility == 1,
              'is-warning': user.role.visibility > 1 && user.role.visibility < 4,
              'is-info': user.role.visibility == 4
            }"
          >
            <div class="message-body">{{ user.role.description }}</div>
          </article>
          <many2one-field
            label="Responsable"
            v-model="user.manager"
            :fetch="$Api.User.fetchUsers"
            reference="name"
            @click="(item) => $router.push('/users/' + item.id)"
            :columns="columns"
            :inline="false"
            :edit="true"
            data-test="sel-user-manager"
          ></many2one-field>

          <text-field label="Service" data-test="sel-user-service" v-model="user.service" :inline="false" :edit="true" />

          <text-field label="Qualité" data-test="sel-user-quality" v-model="user.quality" :inline="false" :edit="true" />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'users-list',
  data() {
    return {
      user: {
        name: '',
        phone: '',
        role: null,
        manager: null,
      },
      columns: {
        name: {
          title: 'Nom',
          filter: '',
        },
        mail: {
          title: 'E-Mail',
          filter: '',
        },
        'role.name': 'Role',
        service: {
          title: 'Service',
          filter: '',
        },
        quality: {
          title: 'Qualité',
          filter: '',
        },
      },
    };
  },
  methods: {
    openCreateModal() {
      this.$refs.createUserModal.open();
    },
    createUser() {
      // eslint-disable-next-line
      axios.post('/users', this.user).then((response) => {
        if (response.data.success) {
          this.user = {
            uid: '',
            phone: '',
            role: null,
            manager: null,
            engagement: null,
          };
          this.$refs.userList.refresh();
          this.$refs.createUserModal.close();
          this.$router.push({
            name: 'User',
            params: { id: response.data.id },
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.infos,
.agencies {
  display: inline-block;
}

.infos {
  width: 34%;
}

.agencies {
  width: 60%;
}
</style>
